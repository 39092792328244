import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
// import expireIn from "redux-persist-transform-expire-in";

const expireAt = 900000; // expire in 1s
const expirationKey = "expirationKey";

export const actionTypes = {
  Login: "[Login] Action",
  LoginGG: "[Login GG] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  LogoutAuth:"[LogoutAuth] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  // { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"], transforms: [expireIn(expireAt, expirationKey, [])] },
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    console.log("action.type", action.type);
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload;
        return { authToken, user: user };
        // return { authToken, user: user.payload };
        // return { authToken, user: undefined };
      }

      case actionTypes.LoginGG: {
        const { authToken, user } = action.payload;
        return { authToken, user: user };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        const { authToken } = action.payload;

        window.location.href = 'https://t4mobile-paperless.wdc.com/api/login/saml/logout?token='+authToken; 
        // TODO: Change this code. Actions in reducer aren't allowed.
        // return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.LogoutAuth: {
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  // login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  login: (authToken, user) => ({ type: actionTypes.Login, payload: { authToken, user } } ),
  // login: (authToken, user) => {
  //   console.log('authToken', authToken)
  //   console.log('user', user)
    
  //   return { type: actionTypes.Login, payload: { authToken, user } }
    
  //   },
  loginGG: (authToken, user) => ({ type: actionTypes.LoginGG, payload: { authToken, user } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: (authToken) => ({ type: actionTypes.Logout, payload: { authToken } }),
  logoutAuth: () => ({ type: actionTypes.LogoutAuth }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();

  //   yield put(actions.fulfillUser(user));
  // });
}
