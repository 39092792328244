import { createSlice } from "@reduxjs/toolkit";

const initialDashboardsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  dashboardForEdit: undefined,
  hospital: null,
  industry: null,
  nationalityType: null,
  nationalityVal: null,
  workerMonth: null,
  workerVal: null,
  lastError: null,
  optionFormNo: null,
  periods_option: null,
  checkItem_option: null,
  attribute_option: null,
  summaryDashborad: null,
  checksheetReport: null,
  checksheetReport_totalDocs:0,

  checksheetReport_sizePerPage:10,
  checksheetReport_page:1,
  fillter:null,
  checksheetReportById: null,
  loading_checksheet_report_detail:false,
  targetArea_option:null
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: initialDashboardsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findDashboards
    dashboardsFetched: (state, action) => {
      const { hospital, industry, nationality, worker } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hospital = hospital.values;
      state.industry = industry.values;
      state.nationalityType = nationality.nationalityType;
      state.nationalityVal = nationality.values;
      var con_month = [];
      if (worker.month) {
        worker.month.forEach((month) => {
          var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          var selectedMonthName = months[month - 1];
          con_month.push(selectedMonthName);
        });
      }
      state.workerMonth = worker === null ? [] : con_month;

      var con_val = [];
      if (worker.values) {
        var max_val = 0;
        worker.values.forEach((val) => {
          var val1 = parseInt(val);
          max_val += val1;
          con_val.push(max_val);
        });
      }

      state.workerVal = worker === null ? [] : con_val;
      // state.totalCount = totalCount;
    },
    formNoListFetched: (state, action) => {
      const { form_no, option } = action.payload;
      state.error = null;
      state.formNoList = form_no;
      state.optionFormNo = option;
      state.listLoading = false;
    },
    periodsFetched: (state, action) => {
      state.error = null;
      state.periods_option = action.payload;
      state.listLoading = false;
    },
    checkItemFetched: (state, action) => {
      state.error = null;
      state.checkItem_option = action.payload;
      state.listLoading = false;
    },
    attributeFetched: (state, action) => {
      state.error = null;
      state.attribute_option = action.payload;
      state.listLoading = false;
    },
    summaryDashboradFetched: (state, action) => {
      state.error = null;
      state.summaryDashborad = action.payload;
      state.listLoading = false;
    },
    checksheetReportFetched: (state, action) => {
      const {data,totalDocs} = action.payload
      state.error = null;
      state.checksheetReport = data;
      state.checksheetReport_totalDocs = totalDocs
      state.listLoading = false;
    },
    setPageChecksheetReport: (state, action)=>{
      const {sizePerPage,page} = action.payload
      state.checksheetReport_sizePerPage = sizePerPage;
      state.checksheetReport_page = page;
    },
    setFillter:(state, action)=>{
      state.fillter = action.payload;
    },
    checksheetReportByIdFetched:(state, action)=>{
      state.checksheetReportById = action.payload;
      state.loading_checksheet_report_detail = false
    },
    setLoading_checksheet_report_detail:(state, action)=>{
      state.loading_checksheet_report_detail = action.payload;
    },
    targetAreaFetched: (state, action) => {
      state.error = null;
      state.targetArea_option = action.payload;
      state.listLoading = false;
    },
  },
});
