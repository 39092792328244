import {createSlice} from "@reduxjs/toolkit";

const initialCheckSheetState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesAll:null,
  lastError: null,
  departmentList: null,
  targetAreaList: null,
  periodList: null,
  mandatoryList: null,
  alertList: null,
  data: [],
  checkSheetForEdit: undefined,
  checkFormNo:true,
  errorFormat:null,
  errorMassage:null,
  error:null,
  success:false,

  pageNumber_Attribute:[],
  pageSize_Attribute:[],

  pageNumber_MachineNo:1,
  pageSize_MachineNo:10,

  deleteMachineItemToCheck:null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const checkSheetSlice = createSlice({
  name: "checkSheet",
  initialState: initialCheckSheetState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorMassage = action.payload.errorMassage
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorFormat = null;
      state.errorMassage = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // checkSheetForEdit
    checkSheetFetched: (state, action) => {
      const { checkSheetForEdit } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.errorMassage = null;
      state.errorFormat = null;
      state.checkSheetForEdit = checkSheetForEdit;
      state.checkFormNo = true;
    },
    approvedTemplate: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.errorMassage = null;
    },
    rejectedTemplate: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.errorMassage = null;
    },
    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.departmentList = entities;
      state.errorMassage = null;
    },
    targetAreaListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.targetAreaList = entities;
      state.errorMassage = null;
    },

    machineListFetched: (state, action) => {
      const { totalCount, entities,entitiesAll } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.entitiesAll = entitiesAll
      state.errorMassage = null;
    },

    mandatoryListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.mandatoryList = entities;
      state.errorMassage = null;
    },

    alertListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.alertList = entities;
      state.errorMassage = null;
    },

    periodListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.periodList = entities;
      state.errorMassage = null;
    },

    checkSheetCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.errorMassage = null;
      // state.entities.push(action.payload.department);
    },
    checkFormNo: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.errorFormat = null;
      state.checkFormNo = action.payload
      state.errorMassage = null;
    },

    getError: (state, action) => {
      state.errorFormat = true;
    },

    setSuccess: (state, action)=> {
      state.success = action.payload
    },
    clearError: (state, action)=> {
      state.error = null;
      state.errorMassage = null
    },

    setPageNumberandSize: (state, action)=> {
      const { pageNumber, pageSize } = action.payload
      state.pageNumber_Attribute = pageNumber;
      state.pageSize_Attribute = pageSize
    },

    setPageNumberandSize_MachineNo: (state, action)=> {
      const { pageNumber, pageSize } = action.payload
      state.pageNumber_MachineNo = pageNumber;
      state.pageSize_MachineNo = pageSize
    },

    deleteMachineItemToCheck: (state, action)=> {
      state.deleteMachineItemToCheck = action.payload;
    },


  }
});
