import * as requestFromServer from "./myDocumentsCrud";
import {myDocumentsSliceIpad, callTypes} from "./myDocumentsSlice";
import MyDocumentsUtils from "../myDocuments.utils";

const {actions} = myDocumentsSliceIpad;

export const searchFilter = (queryParams, creator, values) => dispatch => {
  var filter = {
    filter_creator:values?values.creator.toLowerCase():"",
    filter_machine_no:values?values.machine_no.toLowerCase():"",
    filter_form_no:values?values.form_no.toLowerCase():"",
    filter_form_name:values?values.form_name.toLowerCase():"",
    filter_targetArea:values?values.department:"",
    filter_creator_id:creator._id,
    filter_status:values?values.status:"",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  dispatch(actions.myDocumentsFetched( {totalCount: 0, entities: null, searchValue: values} ));
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getMyDocuments(filter)
    .then(response => {
        var data = []
        var response_data = response.data.docs
        
        response_data.map((item,index)=>{
          var machine_no = ''
          item.mandatory_answer.map((item_m,index_m)=>{
            if(item_m.mandatory_select_data){
              if((item_m.mandatory_select_data.mandatory_title) && item_m.mandatory_select_data.mandatory_title == 'Machine No'){
                machine_no = item_m.values
              }
            }
          })

          data.push({
            ...item,
            creator:{
              ...item.creator,
              fullname:(item.creator) ? item.creator.first_name+' '+item.creator.last_name : ''
            },
            creator_fullname:(item.creator) ? item.creator.first_name+' '+item.creator.last_name : '',
            approve_checksheet_status:item.approve_checksheet.status,
            checksheet_template_id_notification_period_period_title: (item.checksheet_template_id.notification.period) ? item.checksheet_template_id.notification.period.period_title : "",
            checksheet_template_id_form_no:item.checksheet_template_id.form_no,
            checksheet_template_id_form_name:item.checksheet_template_id.form_name,
            machine_no:machine_no
          })
        })

        var entities = data
        var totalCount = response.data.totalDocs
        dispatch(actions.myDocumentsFetched( {totalCount: totalCount, entities: entities, searchValue: values} ));
    })
    .catch(error => {
      error.clientMessage = "Can't find in search mydoc";
      dispatch(actions.myDocumentsFetched({ totalCount: 0, entities: null, searchValue: values }));
    });
};

export const fetchMyDocuments = (queryParams, user_id) => dispatch => {
  dispatch(actions.myDocumentsFetched( {totalCount: 0, entities: null} ));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMyDocuments(user_id)
    .then(response => {
        var data = []
        response.data.map((item,index)=>{
          var MachineNo_arr = []
          item.mandatory_answer.map((item,index)=>{
            if(item.mandatory_select){
              if((item.mandatory_select.mandatory_title) && item.mandatory_select.mandatory_title == 'Machine No'){
                MachineNo_arr = (item.values).split('\n');
              }
            }
          })
          data.push({
            ...item,
            creator:{
              ...item.creator,
              fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:''
            },
            creator_fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:'',
            approve_checksheet_status:item.approve_checksheet.status,
            checksheet_template_id_notification_period_period_title: (item.checksheet_template_id.notification.period) ? item.checksheet_template_id.notification.period.period_title : "",
            checksheet_template_id_form_no:item.checksheet_template_id.form_no,
            checksheet_template_id_form_name:item.checksheet_template_id.form_name,
            machineLength:MachineNo_arr.length

          })
        })
        const homepageUtils = new MyDocumentsUtils();
        const filtered = homepageUtils.baseFilter(data, queryParams);
        const { totalCount, entities } = filtered;
        dispatch(actions.myDocumentsFetched( {totalCount, entities} ));
    })
    .catch(error => {
      error.clientMessage = "Can't find mydoc";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.myDocumentsFetched({ totalCount: 0, entities: null }));
    });
};
export const fetchDepartmentList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDepartmentList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.departmentListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find department";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const deleteMyDocument = _id => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMyDocument(_id)
    .then(response => {
      console.log("response", response)
      dispatch(actions.myDocumentDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete homepage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const clearSearchValue = _id => dispatch => {
  dispatch(actions.clearSearchValue())
}

export const clearqueryParam = (value) => dispatch => {
  dispatch(actions.clearqueryParam(value))
}

export const setSearchValue = (value) => dispatch => {
  dispatch(actions.setSearchValue(value))
}









