import axios from "axios";
import { API_URL } from '../../../../config'

// READ
export function getHomepage(filter) {
  // var filter_data = `?filter_creator=${filter.filter_creator}&filter_department=${filter.filter_department}&filter_form_no=${filter.filter_form_no}&filter_form_name=${filter.filter_form_name}&filter_targetArea=${filter.filter_targetArea}&filter_creator_id=${filter.filter_creator_id}&filter_status=${filter.filter_status}&sortOrder=${filter.sortOrder}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`
  
  return axios.get(`${API_URL}/checksheet`,{params:filter});
  // return axios.get(`${API_URL}/checksheet${filter_data}`);
}

export function getCheckSheetById(id) {
  return axios.get(`${API_URL}/checksheet/getByID?id=${id}`);
}

export function createCheckSheet(data) {
  return axios.post(`${API_URL}/checksheet`, data);
}

export function getDepartmentList() {
  return axios.get(`${API_URL}/m_department`);
}

export function getTargetAreaList() {
  return axios.get(`${API_URL}/m_target_department`);
}

export function deleteHomepage(id) {
  return axios.delete(`${API_URL}/checksheet?_id=${id}`);
}



