import {createSlice} from "@reduxjs/toolkit";

const initialCheckSheetState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  departmentList: null,
  periodList: null,
  mandatoryList: null,
  alertList: null,
  data: [],
  checkSheetOPForEdit: null,
  errorMassage:null,
  errorTitle:null,
  error:null,
  success:false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const checkSheetOPSlice = createSlice({
  name: "checkSheet",
  initialState: initialCheckSheetState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorMassage = action.payload.errorMassage
      state.errorTitle = action.payload.errorTitle
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorMassage = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // checkSheetForEdit
    checkSheetFetched: (state, action) => {
      const { checkSheetOPForEdit } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.errorMassage = null
      state.checkSheetOPForEdit = checkSheetOPForEdit;
    },

    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorMassage = null
      state.departmentList = entities;
    },

    mandatoryListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorMassage = null
      state.mandatoryList = entities;
    },

    alertListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorMassage = null
      state.alertList = entities;
    },

    periodListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.errorMassage = null
      state.periodList = entities;
    },

    checkSheetCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.department);
    },
    approveCheckSheet: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    setSuccess: (state, action)=> {
      state.success = action.payload
    },
    clearError: (state, action)=> {
      state.error = null;
      state.errorMassage = null
    },

  }
});
