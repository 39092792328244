import axios from "axios";
import { API_URL } from '../../../../config'

// READ
export function getAllApproveCheckSheet(filter) {
  return axios.get(`${API_URL}/checksheetOP/getWaitByRoleAndDepartment`,{params:filter});
}

export function getDepartmentList() {
  return axios.get(`${API_URL}/m_target_department`);
}





