import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import storage from "redux-persist/lib/storage/session";
import * as auth from "../_redux/authRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "axios";


class Logout extends Component {
  componentDidMount() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    let token = this.props.token
    // let url = `http://localhost/api/login/saml/logout`;
    // axios.get(url, {
    //   headers: {
    //     'Authorization': `Bearer ${token}` 
    //   }
    // })
    //   .then(res => {
    //     this.props.logout();
    //   })
    this.props.logout(token);
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}


export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken), token: auth.authToken }),
  auth.actions
)(Logout);
