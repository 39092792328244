import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";




const ManageDashboardsPage = lazy(() =>
  import("./modules/Dashboards/pages/ManageDashboardsPage")
);
const ManageHomepage = lazy(() =>
  import("./modules/Homepage/pages/ManageHomepage")
);

const ManageMyDocumentsPage = lazy(() =>
  import("./modules/MyDocuments/pages/ManageMyDocumentsPage")
);

const ManageCheckSheetPage = lazy(() =>
  import("./modules/CheckSheet/pages/ManageCheckSheetPage")
);

const ManageSettingPage = lazy(() =>
  import("./modules/Setting/pages/SettingPage")
);

const ManageHomepageIpad = lazy(() =>
  import("./modules/HomepageIpad/pages/ManageHomepage")
);

const ManageMyDocumentsPageIpad = lazy(() =>
  import("./modules/MyDocumentsIpad/pages/ManageMyDocumentsPage")
);

const ManageCheckSheetPageIpad = lazy(() =>
  import("./modules/CheckSheetIpad/pages/ManageCheckSheetPage")
);

const ManageNewCheckSheetPageIpad = lazy(() =>
  import("./modules/NewCheckSheetIpad/pages/ManageMyDocumentsPage")
);

const ManageWaitForApprovePage = lazy(() =>
  import("./modules/WaitForApprove/pages/ManageWaitForApprovePage")
);

const ManageRegisterMachinePage = lazy(() =>
  import("./modules/RegisterMachine/pages/ManageRegisterMachinePage")
);

const ManageApproveCheckSheetPage = lazy(() =>
  import("./modules/ApproveCheckSheet/pages/ManageApproveCheckSheetPage")
);

const ManageCheckSheetOPPageIpad = lazy(() =>
  import("./modules/CheckSheetOPIpad/pages/ManageCheckSheetPage")
);

const ManageRegisterMachineNoPage = lazy(() =>
  import("./modules/RegisterMachineNo/pages/ManageRegisterMachineNoPage")
);

const ManageAboutPage = lazy(() =>
  import("./modules/About/pages/ManageAboutPage")
);

const ManageSummaryReportPage = lazy(() =>
  import("./modules/SummaryReport/pages/ManageSummaryReportPage")
);

const ManageExportExcelPage = lazy(() =>
  import("./modules/ExportExcel/pages/ManageExportExcelPage")
);

const ManageExportExcelMutipleRowPage = lazy(() =>
  import("./modules/ExportExcelMutipleRow/pages/ManageExportExcelMutipleRowPage")
);

const ManageMassUploadPage = lazy(() =>
  import("./modules/MassUpload/pages/ManageMassUploadPage")
);



export default function BasePage() {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );
  const { user } = currentState;
  // Getting curret state of industries list from store (Redux)
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.auth }),
  //   shallowEqual
  // );
  // const { user } = currentState;
// const { totalCount, entities, listLoading } = currentState;
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          (user.role === "Editor" || user.role === "Administrator") && <Redirect exact from="/" to="/homepage" />
        }
        {
          (user.role === "User") && <Redirect exact from="/" to="/OP-new-check-sheet" />
        }
        {
          (user.role === "SuperUser") && <Redirect exact from="/" to="/approve-checksheet" />
        }

        <ContentRoute path="/approve-checksheet" component={ManageApproveCheckSheetPage} />
        <ContentRoute path="/homepage" component={ManageHomepage} />
        <ContentRoute path="/my-documents" component={ManageMyDocumentsPage} />
        <ContentRoute path="/wait-app" component={ManageWaitForApprovePage} />
        <ContentRoute path="/register-machine" component={ManageRegisterMachinePage} />
        <ContentRoute path="/check-sheet" component={ManageCheckSheetPage} />
        <ContentRoute path="/register-machine-no" component={ManageRegisterMachineNoPage} />
        <ContentRoute path="/OP-homepage" component={ManageHomepageIpad} />
        <ContentRoute path="/OP-new-check-sheet" component={ManageNewCheckSheetPageIpad} />
        <ContentRoute path="/OP-my-documents" component={ManageMyDocumentsPageIpad} />
        <ContentRoute path="/OP-check-sheet" component={ManageCheckSheetPageIpad} />
        <ContentRoute path="/app-check-sheet" component={ManageCheckSheetOPPageIpad} />
        <ContentRoute path="/about" component={ManageAboutPage} />
        <ContentRoute path="/summary-report" component={ManageSummaryReportPage} />
        <ContentRoute path="/export-excel" component={ManageExportExcelPage} />
        <ContentRoute path="/multiple-rows-export-excel" component={ManageExportExcelMutipleRowPage} />
        <ContentRoute path="/mass-upload" component={ManageMassUploadPage} />
        <Route path="/setting" component={ManageSettingPage} />
        <Route path="/dashboards" component={ManageDashboardsPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
