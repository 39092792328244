import * as requestFromServer from "./homepageCrud";
import {homepageSlice, callTypes} from "./homepageSlice";
import HomepageUtils from "../homepage.utils";
import { values } from "lodash";

const {actions} = homepageSlice;

export const searchFilter = (queryParams, values) => dispatch => {
  var filter = {
    filter_creator:values?values.creator.toLowerCase():"",
    filter_machine_no:values?values.machine_no.toLowerCase():"",
    filter_form_no:values?values.form_no.toLowerCase():"",
    filter_form_name:values?values.form_name.toLowerCase():"",
    filter_targetArea:values?values.department:"",
    filter_creator_id:"",
    filter_status:values?values.status:"",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  
  dispatch(actions.homepageFetched( {totalCount: 0, entities: null, searchValue: values} ));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHomepage(filter)
    .then(response => {
        var data = []
        var response_data = response.data.docs

        response_data.map((item,index)=>{
          var machine_no = ''
          item.mandatory_answer.map((item_m,index_m)=>{
            if(item_m.mandatory_select_data){
              if((item_m.mandatory_select_data.mandatory_title) && item_m.mandatory_select_data.mandatory_title == 'Machine No'){
                machine_no = item_m.values
              }
            }
          })

          data.push({
            ...item,
            creator:{
              ...item.creator,
              fullname:(item.creator)? item.creator.first_name+' '+item.creator.last_name : ''
            },
            creator_fullname:(item.creator)? item.creator.first_name+' '+item.creator.last_name : '',
            approve_checksheet_status:item.approve_checksheet.status,
            checksheet_template_id_notification_period_period_title: (item.checksheet_template_id.notification.period) ? item.checksheet_template_id.notification.period.period_title : "",
            checksheet_template_id_form_no:item.checksheet_template_id.form_no,
            checksheet_template_id_form_name:item.checksheet_template_id.form_name,
            machine_no:machine_no

          })
        })
        
        var entities = data
        var totalCount = response.data.totalDocs
        dispatch(actions.homepageFetched( {totalCount: totalCount, entities: entities, searchValue: values} ));
    })
    .catch(error => {
      error.clientMessage = "Can't find in search homepage";
      dispatch(actions.homepageFetched({ totalCount: 0, entities: null, searchValue: values }));
    });
};


export const fetchHomepage = (queryParams, department_name, role) => dispatch => {
  var filter = {
    filter_creator:"",
    filter_machine_no:"",
    filter_form_no:"",
    filter_form_name:"",
    filter_targetArea:"",
    filter_creator_id:"",
    filter_status:"",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  dispatch(actions.homepageFetched( {totalCount: 0, entities: null} ));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHomepage(filter)
    .then(response => {
      var response_data = response.data.docs
      var data = []
      response_data.map((item,index)=>{
          var MachineNo_arr = []
          
          item.mandatory_answer.map((item,index)=>{
            if(item.mandatory_select){
              if((item.mandatory_select.mandatory_title) && item.mandatory_select.mandatory_title == 'Machine No'){
                MachineNo_arr = (item.values).split('\n');
              }
            }
          })
          data.push({
            ...item,
            creator:{
              ...item.creator,
              fullname: (item.creator) ? item.creator.first_name+' '+item.creator.last_name : ''
            },
            creator_fullname:(item.creator) ? item.creator.first_name+' '+item.creator.last_name : '',
            approve_checksheet_status:item.approve_checksheet.status,
            checksheet_template_id_notification_period_period_title: (item.checksheet_template_id.notification.period) ? item.checksheet_template_id.notification.period.period_title : "",
            checksheet_template_id_form_no:item.checksheet_template_id.form_no,
            checksheet_template_id_form_name:item.checksheet_template_id.form_name,
            machineLength:MachineNo_arr.length
          })
        })
        var entities = response_data
        var totalCount = response.data.totalDocs
        dispatch(actions.homepageFetched( {totalCount, entities} ));
    })
    .catch(error => {
      error.clientMessage = "Can't find homepage";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.homepageFetched({ totalCount: 0, entities: null }));
    });
};
export const fetchDepartmentList = () => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDepartmentList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.departmentListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find department";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const deleteHomepage = _id => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteHomepage(_id)
    .then(response => {
      console.log("response", response)
      dispatch(actions.homepageOPDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete homepage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearSearchValue = _id => dispatch => {
  dispatch(actions.clearSearchValue())
}

export const updateSearchValue = values => dispatch => {
  dispatch(actions.updateSearchValue(values))
}

export const clearqueryParam = (value) => dispatch => {
  dispatch(actions.clearqueryParam(value))
}








