import {createSlice} from "@reduxjs/toolkit";

const initialGenQrState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesAll: null,
  machinenoList:null,
  alertList: null,
  genQrForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const genQrSlice = createSlice({
  name: "genQr",
  initialState: initialGenQrState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // genQrForEdit
    genQrFetched: (state, action) => {
      const { genQrForEdit } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.genQrForEdit = genQrForEdit;
    },
   
    machinenoListFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.machinenoList = action.payload;
    },

    templateListFetched: (state, action) => {
      const { totalCount, entities, entitiesAll } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = entities;
      state.entitiesAll = entitiesAll;
      state.totalCount = totalCount;
    },

    alertListFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.alertList = action.payload;
    },

  }
});
