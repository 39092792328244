import * as requestFromServer from "./approveCheckSheetCrud";
import {approveCheckSheetSlice, callTypes} from "./approveCheckSheetSlice";
import ApproveCheckSheetUtils from "../approveCheckSheet.utils";

const {actions} = approveCheckSheetSlice;

export const searchFilter = (queryParams, values, data, clearqueryParam) => dispatch => {
  dispatch(actions.approveCheckSheetFetched({ totalCount: 0, entities: null, searchValue: values,clearqueryParam }));
  dispatch(actions.startCall({ callType: callTypes.list }));
  var filter = {
    role:data.role,
    department: values?values.department?values.department:"":"",
    userId: data.userId,
    filter_form_no: values?values.form_no?values.form_no.toLowerCase():"":"",
    filter_form_name: values?values.form_name?values.form_name.toLowerCase():"":"",
    filter_creator: values?values.creator?values.creator.toLowerCase():"":"",
    filter_department: values?values.department?values.department:"":"",
    filter_status: values?values.status?values.status:"":"",
    filter_machine_no:values?values.machine_no?values.machine_no.toLowerCase():"":"",
    sortOrder: queryParams.sortOrder,
    sortField: queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  return requestFromServer
  .getAllApproveCheckSheet(filter)
    .then(response => {
        const data=[]
        var response_data = response.data.docs
        var start_index = ((queryParams.pageNumber-1)*queryParams.pageSize) +1
        response_data.map((item,index)=>{
          var machine_no = ''
          if(item.mandatory_answer){
            item.mandatory_answer.map((item_m,index_m)=>{
              if(item_m.mandatory_select_data){
                if((item_m.mandatory_select_data.mandatory_title) && item_m.mandatory_select_data.mandatory_title == 'Machine No'){
                  machine_no = item_m.values
                }
              }
            })
          }
          
          var temp = {
            ...item,
            indexNumber:index+start_index,
            form_no:item.checksheet_template_id.form_no,
            form_name:item.checksheet_template_id.form_name,
            creator:{
              ...item.creator,
              fullname:(item.creator) ? item.creator.first_name+' '+item.creator.last_name : '',
            },
            approve_checksheet_status:item.approve_checksheet.status,
            machine_no:machine_no
          }
          data.push(temp)
        })
        var entities = data
        var totalCount = response.data.totalDocs
        dispatch(actions.approveCheckSheetFetched({ totalCount: totalCount, entities: entities, searchValue: values ,clearqueryParam}));

    })
    .catch(error => {
      error.clientMessage = "Can't find wait for approved";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.approveCheckSheetFetched({ totalCount: 0, entities: null, searchValue: values,clearqueryParam }));
    });
};

export const fetchDepartmentList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDepartmentList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.departmentListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find department";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const clearSearchValue = _id => dispatch => {
  dispatch(actions.clearSearchValue())
}

export const clearqueryParam = (value) => dispatch => {
  dispatch(actions.clearqueryParam(value))
}

export const setSearchValue = (value) => dispatch => {
  dispatch(actions.setSearchValue(value))
}

