import * as requestFromServer from "./myDocumentsCrud";
import {myDocumentsSlice, callTypes} from "./myDocumentsSlice";

const {actions} = myDocumentsSlice;

export const fetchDepartmentList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDepartmentList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.departmentListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find department";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchTargetAreaList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTargetAreaList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.targetAreaListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find target area";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const searchFilter = (queryParams, creatorId, values) => dispatch => {
  var filter = {
    filter_creator: values?values.creator?values.creator.toLowerCase():"":"",
    filter_department: values?values.department?values.department:"":"",
    filter_form_no: values?values.form_no?values.form_no.toLowerCase():"":"",
    filter_form_name: values?values.form_name?values.form_name.toLowerCase():"":"",
    filter_targetArea: values?values.targetArea?values.targetArea:"":"",
    filter_creator_id: creatorId,
    filter_status:values?values.status?values.status:"":"",
    filter_machine_no: "",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  dispatch(actions.allFetched({ totalCountAll: 0, entitiesAll: null, searchValue: values }));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMyDocuments(filter)
    .then(response => {
        var response_data = response.data.docs
        var data = []
        response_data.map((item,index)=>{
          data.push({
            ...item,
            creator:{
              ...item.creator,
              fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:''
            },
            creator_fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:'',
            approve_template_status:(item.approve_template) ? item.approve_template.status : '',
            notification_period_period_title:(item.notification.period) ? item.notification.period.period_title : "",
            machineLength:(item.machine) ? item.machine.length : 0
          })
        })

        var entities = data
        var totalCount = response.data.totalDocs
        dispatch(actions.allFetched({ totalCountAll: totalCount, entitiesAll: entities, searchValue: values }));
    })
    .catch(error => {
      error.clientMessage = "Can't find inspectionRounds";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.allFetched({ totalCountAll: 0, entitiesAll: null, searchValue: values }));
    });
};


export const deleteMyDocument = _id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMyDocument(_id)
    .then(response => {
      dispatch(actions.myDocumentDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete homepage";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCheckSheetById =(id) => dispatch => {
  if (!id) {
    return dispatch(actions.checkSheetFetched({ checkSheetForEdit: undefined }));
  }else{
    return requestFromServer
      .getCheckSheetById(id)
      .then(response => {
        let filtered = response.data
        dispatch(actions.startCall({ callType: callTypes.action }));
        dispatch(actions.checkSheetFetched({ checkSheetForEdit: filtered }));
      })
      .catch(error => {
        error.clientMessage = "Can't find checksheet";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
}

export const clearSearchValue = _id => dispatch => {
  dispatch(actions.clearSearchValue())
}

export const clearqueryParam = (value) => dispatch => {
  dispatch(actions.clearqueryParam(value))
}

export const setSearchValue = (value) => dispatch => {
  dispatch(actions.setSearchValue(value))
}









