import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { homepageSlice } from "../app/modules/Homepage/_redux/homepage/homepageSlice";
import { homepageSlice as homepageIpadSlice } from "../app/modules/HomepageIpad/_redux/homepage/homepageSlice";
import { dashboardsSlice } from "../app/modules/Dashboards/_redux/dashboards/dashboardsSlice";
import { grantAccessSlice } from "../app/modules/Setting/_redux/grantAccess/grantAccessSlice";
import { approvalLevelTSlice } from "../app/modules/Setting/_redux/approvalLevelT/approvalLevelTSlice";
import { approvalLevelOSlice } from "../app/modules/Setting/_redux/approvalLevelO/approvalLevelOSlice";

import { departmentSlice } from "../app/modules/Setting/_redux/department/departmentSlice";
import { rolesSlice } from "../app/modules/Setting/_redux/roles/rolesSlice";
import { periodSlice } from "../app/modules/Setting/_redux/period/periodSlice";
import { checkSheetSlice } from "../app/modules/CheckSheet/_redux/checkSheet/checkSheetSlice";
import { checkSheetOPSlice } from "../app/modules/CheckSheetOPIpad/_redux/checkSheetOP/checkSheetOPSlice";
import { myDocumentsSlice } from "../app/modules/MyDocuments/_redux/myDocuments/myDocumentsSlice";
import { myDocumentsSliceIpad } from "../app/modules/MyDocumentsIpad/_redux/myDocuments/myDocumentsSlice";
import { myDocumentsSlice as newCheckSheetIpad } from "../app/modules/NewCheckSheetIpad/_redux/newCheckSheet/myDocumentsSlice";
import { waitForApproveSlice } from "../app/modules/WaitForApprove/_redux/waitForApprove/waitForApproveSlice";
import { approveCheckSheetSlice } from "../app/modules/ApproveCheckSheet/_redux/approveCheckSheet/approveCheckSheetSlice";
import { registerMachineSlice } from "../app/modules/RegisterMachine/_redux/registerMachine/registerMachineSlice";
import { registerMachineNoSlice } from "../app/modules/RegisterMachineNo/_redux/registerMachineNo/registerMachineNoSlice";
import { genQrSlice } from "../app/modules/Setting/_redux/genQR/genQrSlice";
import { targetAreaSlice } from "../app/modules/Setting/_redux/targetArea/targetAreaSlice";
import { summaryReportSlice } from "../app/modules/SummaryReport/_redux/summaryReport/summaryReportSlice";
import { exportExcelSlice } from "../app/modules/ExportExcel/_redux/exportExcel/exportExcelSlice";
import { exportExcelMutipleRowSlice } from "../app/modules/ExportExcelMutipleRow/_redux/exportExcelMutipleRow/exportExcelMutipleRowSlice";

import { massUploadSlice } from "../app/modules/MassUpload/_redux/massUpload/massUploadSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  homepage: homepageSlice.reducer,
  homepageIpad: homepageIpadSlice.reducer,
  dashboards: dashboardsSlice.reducer,
  grantAccess: grantAccessSlice.reducer,
  approvalLevelT: approvalLevelTSlice.reducer,
  approvalLevelO: approvalLevelOSlice.reducer,
  department: departmentSlice.reducer,
  roles: rolesSlice.reducer,
  period: periodSlice.reducer,
  checkSheet: checkSheetSlice.reducer,
  checkSheetOP: checkSheetOPSlice.reducer,
  myDocuments: myDocumentsSlice.reducer,
  myDocumentsIpad: myDocumentsSliceIpad.reducer,
  waitForApprove: waitForApproveSlice.reducer,
  approveCheckSheet: approveCheckSheetSlice.reducer,
  registerMachine: registerMachineSlice.reducer,
  registerMachineNo: registerMachineNoSlice.reducer,
  genQr: genQrSlice.reducer,
  targetArea: targetAreaSlice.reducer,
  massUpload: massUploadSlice.reducer,
  summaryReport: summaryReportSlice.reducer,
  exportExcel: exportExcelSlice.reducer,
  exportExcelMutipleRow: exportExcelMutipleRowSlice.reducer,
  newCheckSheetIpad: newCheckSheetIpad.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
