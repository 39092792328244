import {createSlice} from "@reduxjs/toolkit";

const initialMassUploadState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesAll:[],
  lastError: null,
  formNoList: null,
  periodList: null,
  mandatoryList: null,
  alertList: null,
  data: [],
  massUploadForEdit: undefined,
  checkFormNo:true
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const massUploadSlice = createSlice({
  name: "massUpload",
  initialState: initialMassUploadState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // massUploadForEdit
    massUploadFetched: (state, action) => {
      const { massUploadForEdit } = action.payload;
      state.actionsLoading = false;
      // state.listLoading = false;
      state.error = null;
      state.massUploadForEdit = massUploadForEdit;
    },
    approvedTemplate: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    rejectedTemplate: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    formNoListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.formNoList = entities;
    },

    machineListFetched: (state, action) => {
      const { totalCount, entities,entitiesAll } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.entitiesAll = entitiesAll
    },

    mandatoryListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.mandatoryList = entities;
    },

    alertListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.alertList = entities;
    },

    periodListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.periodList = entities;
    },

    massUploadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.department);
    },
    
    checkFormNo: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.checkFormNo = action.payload
    },

  }
});
