import {createSlice} from "@reduxjs/toolkit";

const initialExportExcelMutipleRowState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  formNoList: null,
  error: null,
  optionFormNo: null,
  actionstext:''
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const exportExcelMutipleRowSlice = createSlice({
  name: "exportExcelMutipleRow",
  initialState: initialExportExcelMutipleRowState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    setload:(state, action)=>{
      state.actionsLoading = action.payload.loader;
      state.actionstext = action.payload.text
    },

    formNoListFetched: (state, action) => {
      const  {form_no, option}  = action.payload;
      state.error = null;
      state.formNoList = form_no;
      state.optionFormNo = option
      state.listLoading = false;
    },

  }
});
