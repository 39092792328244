import {createSlice} from "@reduxjs/toolkit";

const initialMyDocumentsState = {
  listLoading: false,
  actionsLoading: false,
  entitiesD: null,
  totalCountD: null,
  departmentList:null,
  targetAreaList: null,
  searchValue: null,
  clearqueryParam:false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const myDocumentsSlice = createSlice({
  name: "newCheckShett",
  initialState: initialMyDocumentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findInspectionRounds
    targetAreaListFetched: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.targetAreaList = entities;
    },

    draftFetched: (state, action) => {
      const { totalCountD, entitiesD, searchValue } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesD = entitiesD;
      state.totalCountD = totalCountD;
      state.searchValue = searchValue;
      state.clearqueryParam = false
    },
    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.departmentList = entities;
    },
    clearSearchValue:(state, action)=>{
      state.searchValue = null
    },
    updateSearchValue:(state, action)=>{
      state.searchValue = action.payload
      state.clearqueryParam = false
    },
    clearqueryParam:(state, action)=>{
      state.clearqueryParam = action.payload
    }

  }
});
