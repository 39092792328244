import axios from "axios";
import { API_URL } from '../../../../config'

// READ
export function getAllDashboards() {
  return axios.get(`${API_URL}/dashboard`);
}
export function getAllFormNo(filter) {
  return axios.get(`${API_URL}/checksheet/formNo`,{params:filter});
}

export function getCheckSheetById(id) {
  return axios.get(`${API_URL}/checksheet/getByID?id=${id}`);
}

export function getAllPeriod() {
  return axios.get(`${API_URL}/m_period`);
}

export function getCheckItemByType(type){
  return axios.get(`${API_URL}/checksheet/itemtocheck?type=${type}`);
}

export function getAttributeByType(type){
  return axios.get(`${API_URL}/checksheet/attributes?type=${type}`);
}

export function getSummaryDashborad(queryParams){
  return axios.get(`${API_URL}/checksheet/report`,{params:queryParams}); // ใช้ service เดียวกับ report แต่ไม่ส่ง pageNumber และ pageSize
}

export function getChecksheetReport(queryParams){
  return axios.get(`${API_URL}/checksheet/report`,{params:queryParams});
}

export function getChecksheetReportById(id,queryParams,status){
  var filter = {
    ...queryParams,
    fillter_formno:id,
    status:status,
    pageNumber:undefined,
    pageSize:undefined
  }
  return axios.get(`${API_URL}/checksheetOP/getDetailByChecksheetID`,{params:filter});
}

export function getTargetAreaList() {
  return axios.get(`${API_URL}/m_target_department`);
}







