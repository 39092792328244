/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useRef} from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { Modal } from "react-bootstrap";
import * as actionHomeIpad from '../../../../../app/modules/HomepageIpad/_redux/homepage/homepageActions'
import * as actionHome from '../../../../../app/modules/Homepage/_redux/homepage/homepageActions'
import * as actionDocIpad from '../../../../../app/modules/MyDocumentsIpad/_redux/myDocuments/myDocumentsActions'
import * as actionDoc from '../../../../../app/modules/MyDocuments/_redux/myDocuments/myDocumentsActions'
import * as actionApproveTem from '../../../../../app/modules/WaitForApprove/_redux/waitForApprove/waitForApproveActions'
import * as actionApproveCheck from '../../../../../app/modules/ApproveCheckSheet/_redux/approveCheckSheet/approveCheckSheetActions'
import * as actionRegisMachine from '../../../../../app/modules/RegisterMachine/_redux/registerMachine/registerMachineActions'
import * as actionDashboard from '../../../../../app/modules/Dashboards/_redux/dashboards/dashboardsActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps, userRoles }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const [modalWarning, setModalWarning] = React.useState(false)
  const [path, setPath] = React.useState('')

  const homepage_edit  = useRef(null)
  const mydocument_edit  = useRef(null)
  const wait_for_approve_edit  = useRef(null)
  const new_template_edit  = useRef(null)
  const register_machine_no_edit  = useRef(null)
  const mass_upload  = useRef(null)
  const summary_report_edit  = useRef(null)

  const setting_edit  = useRef(null)

  const setting_grant_access_edit  = useRef(null)
  const setting_approval_level_for_operator_edit  = useRef(null)
  const setting_roles_data_edit  = useRef(null)
  const setting_period_data_edit  = useRef(null)
  const setting_department_data_edit  = useRef(null)
  const setting_generate_QR_edit  = useRef(null)
  const setting_target_area_edit  = useRef(null)

  const homepage_user  = useRef(null)
  const mydocument_user  = useRef(null)
  const wait_for_approve_user  = useRef(null)
  const new_check_sheet_user  = useRef(null)

  const export_excel  = useRef(null)
  const export_excel_multiple_rows  = useRef(null)

  const dashboards_summary  = useRef(null)
  const dashboards_report  = useRef(null)


  const history = useHistory()
  const dispatch = useDispatch();

  function onCancel(){
    console.log('onCancel')
    setModalWarning(false)
  }
  function onSubmit(){
    history.push(path)
    setModalWarning(false)
    console.log('onSubmit')
  }
  function onChangeMenu(menu,ref){
    console.log('onChangeMenu',menu)
    dispatch(actionHomeIpad.clearSearchValue())
    dispatch(actionHome.clearSearchValue())
    dispatch(actionDocIpad.clearSearchValue())
    dispatch(actionDoc.clearSearchValue())
    dispatch(actionApproveTem.clearSearchValue())
    dispatch(actionApproveCheck.clearSearchValue())
    dispatch(actionRegisMachine.clearSearchValue())
    dispatch(actionDashboard.clearSummaryDashborad())
    dispatch(actionDashboard.clearChecksheetReport())

    var url = (window.location).toString()
    if(url.indexOf('check-sheet') > -1 && (url.indexOf('/edit')>-1 || url.indexOf('/new')>-1 || url.indexOf('/duplicate')>-1)){
      console.log('checkcheck')
      setModalWarning(true)
      setPath(menu)
    }else{
      ref.current.click();
      // history.push(menu)
    }
  }

  return (
    <>
      <Modal
        size='sm'
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalWarning}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{`Changes you made may not be saved.`}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-light btn-elevate"
            style={{width:'80px'}}
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            className="btn btn-primary btn-elevate"
            style={{width:'80px'}}
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
        
      </Modal>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/homepage", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/homepage",homepage_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Homepage</span>
          </div>
          <NavLink className="menu-link" ref={homepage_edit} to="/homepage" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Homepage</span>
          </NavLink>
        </li> }

        {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/my-documents", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/my-documents",mydocument_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">My Documents</span>
          </div>
          <NavLink className="menu-link" ref={mydocument_edit} to="/my-documents" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">My Documents</span>
          </NavLink>
        </li>}

        {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/wait-app", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/wait-app",wait_for_approve_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Wait for approve</span>
          </div>
          <NavLink className="menu-link" ref={wait_for_approve_edit} to="/wait-app" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Wait for approve</span>
          </NavLink>
        </li>}


        {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/check-sheet", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/check-sheet",new_template_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">New Template</span>
          </div>
          <NavLink className="menu-link" ref={new_template_edit} to="/check-sheet" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">New Template</span>
          </NavLink>
        </li>}

        {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/register-machine", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/register-machine",register_machine_no_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Register Machine No.</span>
          </div>
          <NavLink className="menu-link" ref={register_machine_no_edit} to="/register-machine" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Register Machine No.</span>
          </NavLink>
        </li>}

        {/* {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/mass-upload", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/mass-upload",mass_upload)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Mass Upload</span>
          </div>
          <NavLink className="menu-link" ref={mass_upload} to="/mass-upload" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Mass Uploas</span>
          </NavLink>
        </li>} */}

        {/* {(userRoles === "Editor" || userRoles === "Administrator") && <li
          className={`menu-item ${getMenuItemActive("/summary-report", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/summary-report",summary_report_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Summary Report</span>
          </div>
          <NavLink className="menu-link" ref={summary_report_edit} to="/summary-report" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Summary Report</span>
          </NavLink>
        </li>} */}

        

        {/* Setting */}
        {/*begin::1 Level*/}
        {(userRoles === "Administrator" || userRoles === "Editor") && <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/setting",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/setting",userRoles === "Administrator"?setting_grant_access_edit:setting_approval_level_for_operator_edit)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
              </span>
              <span className="menu-text">Setting</span>
          </div>
          <NavLink className="menu-link" ref={setting_edit} to="/setting" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
            </span>
            <span className="menu-text">Setting</span>
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Setting</span>
                </span>
              </li>
              {(userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/grant-access"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/grant-access",setting_grant_access_edit)}>
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                      </span>
                      <span className="menu-text">Grant Access</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_grant_access_edit} to="/setting/grant-access" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Grant Access</span>
                  </NavLink>
                </li>
              }
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/setting/approval-level-template"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="" onClick={()=> onChangeMenu("/setting/approval-level-template")}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Approval Level For Template</span>
                </NavLink>
              </li> */}

              <li
                className={`menu-item ${getMenuItemActive(
                  "/setting/approval-level-operator"
                )}`}
                aria-haspopup="true"
              >
                <div className="menu-link" onClick={()=> onChangeMenu("/setting/approval-level-operator",setting_approval_level_for_operator_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Approval Level For Operator</span>
                </div>
                <NavLink className="menu-link" ref={setting_approval_level_for_operator_edit} to="/setting/approval-level-operator" style={{display:'none'}}>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                  </span>
                  <span className="menu-text">Approval Level For Operator</span>
                </NavLink>
              </li>

              {(userRoles === "Administrator") && 

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/roles"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/roles",setting_roles_data_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Roles Data</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_roles_data_edit} to="/setting/roles" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Roles Data</span>
                  </NavLink>
                </li>
              }
              {(userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/period"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/period",setting_period_data_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Period Data</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_period_data_edit} to="/setting/period" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Period Data</span>
                  </NavLink>
                </li>
              }
              {(userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/department"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/department",setting_department_data_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Department Data</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_department_data_edit} to="/setting/department" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Department Data</span>
                  </NavLink>
                </li>
              }

              {(userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/targetArea"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/targetArea",setting_target_area_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Target Area Data</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_target_area_edit} to="/setting/targetArea" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Target Area Data</span>
                  </NavLink>
                </li>
              }
              


              {(userRoles === "Administrator" || userRoles === "Editor") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/setting/genqr"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/setting/genqr",setting_generate_QR_edit)}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Generate QR</span>
                  </div>
                  <NavLink className="menu-link" ref={setting_generate_QR_edit} to="/setting/genqr" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Generate QR</span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>}
        {/*end::1 Level*/}

        

        {(userRoles === "User" || userRoles === "SuperUser") &&  <li
          className={`menu-item ${getMenuItemActive("/OP-homepage", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/OP-homepage",homepage_user)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Homepage</span>
          </div>
          <NavLink className="menu-link" ref={homepage_user} to="/OP-homepage" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Homepage</span>
          </NavLink>
        </li>}

        {(userRoles === "SuperUser") &&  <li
          className={`menu-item ${getMenuItemActive("/approve-checksheet", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/approve-checksheet",wait_for_approve_user)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Wait For Approve</span>
          </div>
          <NavLink className="menu-link" ref={wait_for_approve_user} to="/approve-checksheet" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Wait For Approve</span>
          </NavLink>
        </li>}

        {(userRoles === "User" || userRoles === "SuperUser") &&  <li
          className={`menu-item ${getMenuItemActive("/OP-new-check-sheet", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/OP-new-check-sheet",new_check_sheet_user)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">New check sheet</span>
          </div>
          <NavLink className="menu-link" ref={new_check_sheet_user} to="/OP-new-check-sheet" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">New check sheet</span>
          </NavLink>
        </li>}

        {(userRoles === "User" || userRoles === "SuperUser") &&  <li
          className={`menu-item ${getMenuItemActive("/OP-my-documents", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/OP-my-documents",mydocument_user)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">MyDocuments</span>
          </div>
          <NavLink className="menu-link" ref={mydocument_user} to="/OP-my-documents" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">MyDocuments</span>
          </NavLink>
        </li>}

        <li
          className={`menu-item ${getMenuItemActive("/export-excel", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/export-excel",export_excel)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Export Excel</span>
          </div>
          <NavLink className="menu-link" ref={export_excel} to="/export-excel" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Export Excel</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/multiple-rows-export-excel", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/multiple-rows-export-excel",export_excel_multiple_rows)}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Export Excel (Multiple Rows)</span>
          </div>
          <NavLink className="menu-link" ref={export_excel_multiple_rows} to="/multiple-rows-export-excel" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Export Excel (Multiple Rows)</span>
          </NavLink>
        </li>

        {/* Dashboard */}
        {/*begin::1 Level*/}
        {
          
        // (userRoles === "Administrator" || userRoles === "Editor") &&
         <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/dashboards",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link" onClick={()=> onChangeMenu("/dashboards",dashboards_summary)}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
          </div>
          <NavLink className="menu-link" ref={dashboards_summary} to="/dashboards" style={{display:'none'}}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Dashboard</span>
                </span>
              </li>
              {
              // (userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/dashboards/summaryDashboards"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/dashboards/summaryDashboards",dashboards_summary)}>
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                      </span>
                      <span className="menu-text">Summary Dashboard</span>
                  </div>
                  <NavLink className="menu-link" ref={dashboards_summary} to="/dashboards/summaryDashboards" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Summary Dashboard</span>
                  </NavLink>
                </li>
              }

{
// (userRoles === "Administrator") && 
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/dashboards/checksheetReport"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link" onClick={()=> onChangeMenu("/dashboards/checksheetReport",dashboards_report)}>
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                      </span>
                      <span className="menu-text">Checksheet Report</span>
                  </div>
                  <NavLink className="menu-link" ref={dashboards_report} to="/dashboards/checksheetReport" style={{display:'none'}}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                    </span>
                    <span className="menu-text">Checksheet Report</span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </li>
        
        }
        {/*end::1 Level*/}

      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
