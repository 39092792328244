import {createSlice} from "@reduxjs/toolkit";

const initialApprovalLevelOState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesForm:null,
  totalCountForm:0,
  approvalLevelOForEdit: undefined,
  lastError: null,
  entitiesDetail:null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const approvalLevelOSlice = createSlice({
  name: "approvalLevelO",
  initialState: initialApprovalLevelOState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findApprovalLevelO
    approvalLevelOFetcheds: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findApprovalLevelODetail
    approvalLevelOFetchedsDetail: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.approvalLevelOForEdit = action.payload;
    },
    formForApproveFetcheds: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesForm = entities;
      state.totalCountForm = totalCount;
    },
    // createApprovalLevelO
    approvalLevelOCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.approvalLevelO);
    },
    // updateApprovalLevelO
    approvalLevelOUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.approvalLevelO._id) {
          return action.payload.approvalLevelO;
        }
        return entity;
      });
    },
  }
});
