import {createSlice} from "@reduxjs/toolkit";

const initialApprovalLevelTState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesDepartment:null,
  entitiesForm:null,
  totalCountDepartment:0,
  totalCountForm:0,
  approvalLevelTForEdit: undefined,
  lastError: null,
  entitiesDetail:null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const approvalLevelTSlice = createSlice({
  name: "approvalLevelT",
  initialState: initialApprovalLevelTState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getApprovalLevelTById
    approvalLevelTFetched: (state, action) => {
      state.actionsLoading = false;
      state.approvalLevelTForEdit = action.payload.approvalLevelTForEdit;
      state.error = null;
    },
    // findApprovalLevelT
    approvalLevelTFetcheds: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    approvalLevelTDepartmentFetcheds: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesDepartment = entities;
      state.totalCountDepartment = totalCount;
    },
    // findApprovalLevelTDetail
    approvalLevelTFetchedsDetail: (state, action) => {
      state.approvalLevelTForEdit = action.payload;
    },
    // createApprovalLevelT
    approvalLevelTCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.approvalLevelT);
    },
    // updateApprovalLevelT
    approvalLevelTUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.approvalLevelT._id) {
          return action.payload.approvalLevelT;
        }
        return entity;
      });
    },
    // deleteApprovalLevelT
    approvalLevelTDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el._id !== action.payload._id);
    },
   
  }
});
