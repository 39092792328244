import axios from "axios";
import { API_URL } from '../../../../config'

// READ
export function getHomepage(filter) {
  // var filter = `?filter_creator=${filter.filter_creator}&filter_machine_no=${filter.filter_machine_no}&filter_form_no=${filter.filter_form_no}&filter_form_name=${filter.filter_form_name}&filter_targetArea=${filter.filter_targetArea}&filter_status=${filter.filter_status}&sortOrder=${filter.sortOrder}&sortField=${filter.sortField}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`
  return axios.get(`${API_URL}/checksheetOP`,{params:filter});
  // return axios.get(`${API_URL}/checksheetOP${filter}`);
}
export function getDepartmentList() {
  return axios.get(`${API_URL}/m_target_department`);
}

export function deleteHomepage(id) {
  return axios.delete(`${API_URL}/checksheetOP?_id=${id}`);
}


