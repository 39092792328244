import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import moment from 'moment'
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const handleDateChangeRaw = (e) => {
  e.preventDefault();
}

export function DatePickerFieldWithoutValidate({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  var temp_date = field.value
  var data = ''
  if(field.value){
    var data = moment(temp_date).format('YYYY-MM-DD')
  }
  
  
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses()}
        style={{ width: "100%"}}
        // {...field}
        onChangeRaw={(e)=>handleDateChangeRaw(e)}
        {...props}
        selected={data?new Date(data):null}
        dateFormat='dd/MM/yyyy'
        onChange={val => {
          setFieldValue(field.name, val);
        }}
        autoComplete='off'
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : ( ""
        // <div className="feedback">
        //   กรุณาใส่ <b>{props.label}</b> ในรูปแบบ 'วว/ดด/ปปปป' 
        // </div>
      )}
    </>
  );
}
