import {createSlice} from "@reduxjs/toolkit";

const initialTargetAreaState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  targetAreaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const targetAreaSlice = createSlice({
  name: "targetArea",
  initialState: initialTargetAreaState,
  reducers: {
    catchError: (state, action) => {
      if(action.payload.error.response.status != 409){
        state.error = `${action.type}: ${action.payload.error}`;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTargetAreaById
    targetAreaFetched: (state, action) => {
      state.actionsLoading = false;
      state.targetAreaForEdit = action.payload.targetAreaForEdit;
      state.error = null;
    },
    // findTargetArea
    targetAreasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTargetArea
    targetAreaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.targetArea);
    },
    // updateTargetArea
    targetAreaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.targetArea._id) {
          return action.payload.targetArea;
        }
        return entity;
      });
    },
    // deleteTargetArea
    targetAreaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el._id !== action.payload._id);
    },
   
  }
});
