import * as requestFromServer from "./waitForApproveCrud";
import {waitForApproveSlice, callTypes} from "./waitForApproveSlice";
import WaitForApproveUtils from "../waitForApprove.utils";

const {actions} = waitForApproveSlice;

export const fetchDepartmentList = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDepartmentList()
    .then(response => {
      const  entities  = response.data;
      dispatch(actions.departmentListFetched( entities ));
    })
    .catch(error => {
      error.clientMessage = "Can't find department";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}


export const fetchWaitForApprove = (queryParams,data) => dispatch => {
  var filter = {
    filter_creator: "",
    filter_department: "",
    filter_form_no: "",
    filter_form_name: "",
    filter_targetArea: "",
    filter_creator_id: "",
    filter_status:"Wait",
    filter_machine_no: "",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllWaitForApprove(filter)
    .then(response => {
      var data = []
      var response_data = response.data.docs
      var start_index = ((queryParams.pageNumber-1)*queryParams.pageSize) +1
      response_data.map((item,index)=>{
        data.push({
          ...item,
          indexNumber:index+start_index,
          creator:{
            ...item.creator,
            fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:''
          }
        })
      })
      var entities = data
      var totalCount = response.data.totalDocs
        dispatch(actions.waitForApproveFetched({ totalCount: totalCount, entities: entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find fetchHomepageWait";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.waitForApproveFetched({ totalCount: 0, entities: null }));
    });
};

export const searchFilter = (queryParams, values) => dispatch => {
  var filter = {
    filter_creator: values?values.creator?values.creator.toLowerCase():"":"",
    filter_department: values?values.department?values.department:"":"",
    filter_form_no: values?values.form_no?values.form_no.toLowerCase():"":"",
    filter_form_name: values?values.form_name?values.form_name.toLowerCase():"":"",
    filter_targetArea: values?values.targetArea?values.targetArea:"":"",
    filter_creator_id: "",
    filter_status:"Wait",
    filter_machine_no: "",
    sortOrder: queryParams.sortOrder, 
    sortField:  queryParams.sortField,
    pageNumber: queryParams.pageNumber,
    pageSize: queryParams.pageSize
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllWaitForApprove(filter)
    .then(response => {
        var data = []
        var response_data = response.data.docs
        var start_index = ((queryParams.pageNumber-1)*queryParams.pageSize) +1
        response_data.map((item,index)=>{
          data.push({
            ...item,
            indexNumber:index+start_index,
            creator:{
              ...item.creator,
              fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:''
            },
            creator_fullname:(item.creator)?item.creator.first_name+' '+item.creator.last_name:'',
            approve_template_status:(item.approve_template) ? item.approve_template.status : '',
            notification_period_period_title:(item.notification.period) ? item.notification.period.period_title : "",
            machineLength:(item.machine) ? item.machine.length : 0
          })
        })

        var entities = data
        var totalCount = response.data.totalDocs
        dispatch(actions.waitForApproveFetched({ totalCount: totalCount, entities: entities, searchValue: values }));
       
    })
    .catch(error => {
      error.clientMessage = "Can't find wait for approved";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.waitForApproveFetched({ totalCount: 0, entities: null, searchValue: values }));
    });
};

export const clearSearchValue = _id => dispatch => {
  dispatch(actions.clearSearchValue())
}

export const clearqueryParam = (value) => dispatch => {
  dispatch(actions.clearqueryParam(value))
}

export const setSearchValue = (value) => dispatch => {
  dispatch(actions.setSearchValue(value))
}

