import * as requestFromServer from "./dashboardsCrud";
import { dashboardsSlice, callTypes } from "./dashboardsSlice";

const { actions } = dashboardsSlice;

export const fetchDashboards = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDashboards()
    .then((response) => {
      const { hospital, industry, nationality, worker } = response.data;
      dispatch(
        actions.dashboardsFetched({ hospital, industry, nationality, worker })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find dashboards";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(
        actions.dashboardsFetched({
          hospital: null,
          industry: null,
          nationality: null,
          worker: null,
        })
      );
    });
};

export const fetchFormNoList = (period) => (dispatch) => {
  var filter = {
    fillter_period: period ? period : undefined,
  };
  dispatch(actions.formNoListFetched({ form_no: [] }));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllFormNo(filter)
    .then((response) => {
      var reduce_entities = response.data;

      const option = [{ label: "All", value: null }];
      reduce_entities.forEach((element) => {
        var item = {};
        Object.assign(item, {
          label: `${element.form_no} (Rev. ${element.doc_rev})`,
        });
        Object.assign(item, { value: element._id });
        option.push(item);
      });
      dispatch(
        actions.formNoListFetched({ form_no: reduce_entities, option: option })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find formno";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTargetAreaList = (department) => (dispatch) => {
  dispatch(actions.targetAreaFetched(null));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTargetAreaList()
    .then((response) => {
      var data = [{ label: "All", value: null }];
      response.data.map((item) => {
        data.push({
          label: item.department_title,
          value: item.department_title,
        });
      });
      dispatch(actions.targetAreaFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find formno";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPeriods = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllPeriod()
    .then((response) => {
      var data = [];
      response.data.map((item) => {
        data.push({
          ...item,
          label: item.period_title,
          value: item._id,
        });
      });
      dispatch(actions.periodsFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find period";
      dispatch(actions.periodsFetched(null));
    });
};

export const fetchCheckItemByType = (type) => (dispatch) => {
  return requestFromServer
    .getCheckItemByType(type)
    .then((response) => {
      var data = [{ label: "All", value: null }];
      var response_data = response.data;
      if (response_data) {
        response_data.item.map((item) => {
          data.push({
            label: item,
            value: item,
          });
        });
      }
      dispatch(actions.checkItemFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find period";
      dispatch(actions.checkItemFetched(null));
    });
};

export const fetchAttributeByType = (type) => (dispatch) => {
  return requestFromServer
    .getAttributeByType(type)
    .then((response) => {
      var data = [{ label: "All", value: null }];
      var response_data = response.data;
      if (response_data) {
        response_data.item.map((item) => {
          data.push({
            label: item,
            value: item,
          });
        });
      }
      dispatch(actions.attributeFetched(data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find period";
      dispatch(actions.attributeFetched(null));
    });
};

export const fetchSummaryDashborad = (queryParams) => (dispatch) => {
  return requestFromServer
    .getSummaryDashborad(queryParams)
    .then((response) => {
      dispatch(actions.summaryDashboradFetched(response.data));
    })
    .catch((error) => {
      dispatch(actions.summaryDashboradFetched(null));
    });
};

export const clearSummaryDashborad = () => (dispatch) => {
  dispatch(actions.summaryDashboradFetched(null));
};

export const fetchChecksheetReport = (queryParams) => (dispatch) => {
  dispatch(actions.checksheetReportFetched({ data: null, totalDocs: 0 }));
  return requestFromServer
    .getChecksheetReport(queryParams)
    .then((response) => {
      var docs = response.data.docs;
      var data = [];
      docs.map((item) => {
        var status_sum = "";
        if (item.status) {
          var Approved = 0;
          var Wait = 0;
          var WaitAction = 0;
          var NotReady = 0;
          var NotChecked = 0;
          item.status.map((item_status) => {
            if (item_status.name == "Approved") {
              Approved = item_status.value;
            }
            if (item_status.name == "Wait") {
              Wait = item_status.value;
            }
            if (item_status.name == "WaitAction") {
              WaitAction = item_status.value;
            }
            if (item_status.name == "Rejected") {
              NotReady = item_status.value;
            }
            if (item_status.name == "Not Checked") {
              NotChecked = item_status.value;
            }
          });
          if (NotReady > 0) {
            status_sum = "Rejected";
          } else if (WaitAction > 0) {
            status_sum = "WaitAction";
          } else if (Wait > 0) {
            status_sum = "Wait";
          } else if (NotChecked > 0) {
            status_sum = "Not Checked";
          }
        }
        data.push({
          ...item,
          status_sum,
        });
      });
      var totalDocs = response.data.totalDocs;
      dispatch(actions.checksheetReportFetched({ data, totalDocs }));
    })
    .catch((error) => {
      dispatch(actions.checksheetReportFetched({ data: [], totalDocs: 0 }));
    });
};

export const clearChecksheetReport = (queryParams) => (dispatch) => {
  dispatch(actions.checksheetReportFetched({ data: null, totalDocs: 0 }));
  dispatch(actions.setPageChecksheetReport({ sizePerPage: 10, page: 1 }));
  dispatch(actions.setFillter(null));
};

export const setPageChecksheetReport = ({ sizePerPage, page }) => (
  dispatch
) => {
  return dispatch(actions.setPageChecksheetReport({ sizePerPage, page }));
};

export const setFillter = (fillter) => (dispatch) => {
  return dispatch(actions.setFillter(fillter));
};

export const fetchChecksheetReportById = (id, fillter, status, total) => (
  dispatch
) => {
  dispatch(actions.setLoading_checksheet_report_detail(true));
  dispatch(actions.checksheetReportByIdFetched(null));
  return requestFromServer
    .getChecksheetReportById(id, fillter, status)
    .then((response) => {
      console.log('fetchChecksheetReportById fillter',fillter.fillter_target_area)
      if (status == "Not Checked") {
        requestFromServer.getCheckSheetById(id).then((res) => {
          var pages = [];
          if (res.data.pages) {
            res.data.pages.map((item) => {
              var checklists = [];
              item.checklists.map((item_checklists) => {
                checklists.push({
                  checklists: item_checklists,
                  values: [""],
                });
              });
              pages.push({
                page_name: item.page_name,
                page_checklists: checklists,
              });
            });
          }
          var mandatory_select = [];
          var data_item = [];
          if (res.data.item_to_check_by_department) {
            console.log('res.data.shift',res.data.shift)
            if (res.data.shift.is_required) {
              res.data.shift.shift.map((item_k, index_j) => {
                res.data.item_to_check_by_department.map((item_i, index_i) => {
                  item_i.items_to_check.map((item_j, index_j) => {
                    console.log('fillter.fillter_target_area == item_i.department if',fillter.fillter_target_area,item_i.department,item_j.machine_no,item_k)
                    
                    if(fillter.fillter_shift){
                      if(fillter.fillter_shift == item_k){
                        if(fillter.fillter_target_area){
                          if(fillter.fillter_target_area == item_i.department){
                            data_item.push({
                              department: item_i.department,
                              machine_no: item_j.machine_no,
                              shift: item_k,
                            });
                          }
                        }else{
                          data_item.push({
                            department: item_i.department,
                            machine_no: item_j.machine_no,
                            shift: item_k,
                          });
                        }
                        
                       
                      }
                    }else{
                      if(fillter.fillter_target_area){
                        if(fillter.fillter_target_area == item_i.department){
                          data_item.push({
                            department: item_i.department,
                            machine_no: item_j.machine_no,
                            shift: item_k,
                          });
                        }
                      }else{
                        data_item.push({
                          department: item_i.department,
                          machine_no: item_j.machine_no,
                          shift: item_k,
                        });
                      }
                     
                      
                    }
                   
                  });
                });
              });
            } else {
              res.data.item_to_check_by_department.map((item_i, index_i) => {
                item_i.items_to_check.map((item_j, index_j) => {
                  if(fillter.fillter_target_area){
                    if(fillter.fillter_target_area == item_i.department){
                      data_item.push({
                        department: item_i.department,
                        machine_no: item_j.machine_no,
                        shift: "",
                      });
                    }
                  }else{
                    data_item.push({
                      department: item_i.department,
                      machine_no: item_j.machine_no,
                      shift: "",
                    });
                  }
                  
                });
              });
            }
          }
          console.log("data_item", data_item, total);
          for (var i = 0; i < parseInt(total); i++) {
            var data_mandatory_select = [];
            if (res.data.mandatory_select) {
              res.data.mandatory_select.map((item, index) => {
                if (item.mandatory_title == "Machine No") {
                  if (
                    res.data.item_to_check_by_department &&
                    res.data.item_to_check_by_department.length > 0
                  ) {
                    data_mandatory_select.push({
                      mandatory_select: {
                        ...item,
                        mandatory_title: "Check For (Area)",
                      },
                      values: data_item[i].department,
                    });

                    data_mandatory_select.push({
                      mandatory_select: {
                        ...item,
                        mandatory_title: res.data.item_to_check
                          ? res.data.item_to_check
                          : item.mandatory_title,
                        mandatory_title_check: item.mandatory_title,
                      },
                      values: data_item[i].machine_no,
                      department: data_item[i].department,
                    });
                  } else {
                    data_mandatory_select.push({
                      mandatory_select: res.data.item_to_check
                        ? res.data.item_to_check
                        : item.mandatory_title,
                      mandatory_title_check: item.mandatory_title,
                      values: "",
                    });
                  }
                } else {
                  data_mandatory_select.push({
                    mandatory_select: item,
                    values: "",
                  });
                }
              });
            }
            mandatory_select.push(data_mandatory_select);
          }

          var attribute_select = [];
          if (res.data.attributes) {
            res.data.attributes.map((item) => {
              attribute_select.push({
                type: item.type,
                values: "",
              });
            });
          }
          var data = [];
          for (var i = 0; i < parseInt(total); i++) {
            var check = true;

            mandatory_select[i].map((item, index) => {
              if (item.mandatory_select.mandatory_title_check == "Machine No") {
                response.data.map((item_c, index_c) => {
                  item_c.mandatory_answer.map((item_m, index_m) => {
                    if (
                      item_m.mandatory_select.mandatory_title == "Machine No"
                    ) {
                      if (
                        item.values == item_m.values &&
                        item_c.department == item.department && data_item[i].shift == item_c.shift
                      ) {
                        check = false;
                      }
                    }
                  });
                });
              }
            });

            if (check) {
              data.push({
                checklists_answer: pages,
                mandatory_answer: mandatory_select[i],
                shift: data_item[i].shift,
                // shift:'',
                attributes: attribute_select,
              });
            }
          }

          console.log("checksheetReportByIdFetched", data);
          dispatch(actions.checksheetReportByIdFetched(data));
        });
      } else {
        dispatch(actions.checksheetReportByIdFetched(response.data));
      }
    })
    .catch((error) => {
      dispatch(actions.checksheetReportByIdFetched(null));
    });
};

export const fetchCheckSheetById = (id) => (
  dispatch
) => {
  return requestFromServer
  .getCheckSheetById(id)
  .then((response) => {
    return response.data
  })
}
