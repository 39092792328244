import React from "react";
import {useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    // classes.push("is-invalid-select");
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    // classes.push("is-valid-select");
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function SelectWithoutValidate({
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses()}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type="select"
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
