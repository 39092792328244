import {createSlice} from "@reduxjs/toolkit";

const initialHomepageState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  departmentList:null,
  searchValue: null,
  clearqueryParam:false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const homepageSlice = createSlice({
  name: "homepage",
  initialState: initialHomepageState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findInspectionRounds
    homepageFetched: (state, action) => {
      const { totalCount, entities, searchValue } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.searchValue = searchValue;
      state.clearqueryParam = false
    },
    checkSheetCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.departmentList = entities;
    },
    homepageOPDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.entities = state.entities.filter(el => el._id !== action.payload._id);
    },
    clearSearchValue:(state, action)=>{
      state.searchValue = null
    },
    updateSearchValue:(state, action)=>{
      state.searchValue = action.payload
      state.clearqueryParam = false
    },
    clearqueryParam:(state, action)=>{
      state.clearqueryParam = action.payload
    }
  }
});
