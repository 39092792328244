/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useCookies } from 'react-cookie';

import {actions} from "../app/modules/Auth/_redux/authRedux";


export function Routes() {

  const dispatch = useDispatch();

  const { isAuthorized, authToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      authToken: auth.authToken
    }),
    shallowEqual
  );

  const [cookies, setCookie, removeCookie] = useCookies(['cookie_key']);
  
  useEffect(() => {
    console.log("isAuthorized", isAuthorized)

    if(!isAuthorized){
      dispatch(actions.logoutAuth());
    }

    // if(){
    if(Object.keys(cookies).length > 0){
      const { token } = cookies.cookie_key
      const userData = cookies.cookie_key.payload
      dispatch(actions.login(token, userData));
    }
    // }

    
    
  }, []);


  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        // <Route>
        //   <AuthPage />
        // </Route>
        <Route path='/auth/login' component={() => { 
          window.location.href = 'https://t4mobile-paperless.wdc.com/api/login/saml'; 
          return null;
        }}/>

      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      {/* <Route path='/logout' component={() => { 
        window.location.href = 'http://localhost/api/login/saml/logout?token='+authToken; 
        return null;
      }}/> */}
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
