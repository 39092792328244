import {createSlice} from "@reduxjs/toolkit";

const initialRegisterMachineState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  departmentList:null,
  searchValue: null,
  clearqueryParam:false,
  searchValue_save: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const registerMachineSlice = createSlice({
  name: "registerMachine",
  initialState: initialRegisterMachineState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    // findRegisterMachine
    registerMachineFetched: (state, action) => {
      const { totalCount, entities, searchValue } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.searchValue = searchValue;
      state.clearqueryParam = false;
    },
    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.listLoading = false;
      state.error = null;
      state.departmentList = entities;
    },
    clearSearchValue:(state, action)=>{
      state.searchValue = null
      state.searchValue_save = null
    },
    clearqueryParam:(state, action)=>{
      state.clearqueryParam = action.payload
    },
    setSearchValue:(state, action)=>{
      state.searchValue_save = action.payload;
      state.clearqueryParam = false
    }
  }
});
