import {createSlice} from "@reduxjs/toolkit";

const initialSummaryReportState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  summaryReportForEdit: undefined,
  summaryList: null,
  targetAreaList: [],
  departmentList:[],
  formNoList:[],
  formNoFullList:[]
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const summaryReportSlice = createSlice({
  name: "summaryReport",
  initialState: initialSummaryReportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    summaryReportFetched: (state, action) => {
      const { data_arr } = action.payload;
      state.summaryList = data_arr
      state.listLoading = false;
      state.error = null;
    },

    previewFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    targetAreaListFetched: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.targetAreaList = entities;
    },
    departmentListFetched: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.departmentList = entities;
    },
    formNoListFetch: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.formNoList = entities;
    },
    formNoFullListFetch: (state, action) => {
      const  entities  = action.payload;
      state.error = null;
      state.formNoFullList = entities;
    },
  }
});
